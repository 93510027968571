import client from "./APIClient"

export default class InvalidationReportApi {
  static getData = (ucid) => {
    return client(`invalidation-report?ucid=${ucid}`)
  }

  static sendEmail = (body) => {
    return client("invalidation-report", { method: "POST", body })
  }

  static create = formData => {
    return client("invalidation-report", { method: "POST", body: formData })
  }

  static exists = (ucid) => {
    return client(`invalidation-report/exists?ucid=${ucid}`)
  }
}
